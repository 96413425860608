<template lang="pug">
.wf-table.full-box
  DataTable(
    v-if="showTable"
    ref="dataTable"
    serverSide
    resource="/meterReading/list"
    downloadResource="/meterReading/export"
    :settingButtonList="['refresh', 'download']"
    :filterFields="filterFields"
    :columns="columns"
    :defaultSortBy="[{prop: 'createTime', order: 'desc'}]"
    :operationWidth="120"
    :operateButtonList="operateButtonList"
    :cellClassNameFunc="readingStatusClassFunc"
    @rowDblClick="handleRowDblClick")
  el-dialog.dialog-box(
    :title="dialogTitle"
    append-to-body
    width="80%"
    :visible.sync="visible"
    :before-close="handleDialogClose")
    .form-box(v-if="visible")
      DataForm(
        ref="form"
        v-model="formData"
        type="grid"
        :gridspanNum="3"
        gridFlow="row"
        :inlineGrid="true"
        labelWidth="130px"
        :formFields="formFields")
    ButtonGroup(
      slot="footer"
      :buttonList="dialogButtonList")
  EditRecord(
    v-model="showEditRecord"
    :recordData="recordData"
  )
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import { mapActions } from 'vuex'
import { readingStatusClassFunc } from '@/utils/common.js'
import { quantityCalc, thisReadingNumCalc } from '@/views/MeterReadManage/MeterReading/calcFunc.js'
import EditRecord from './children/EditRecord.vue'

export default {
  name: 'MeterReadingManage',
  components: { EditRecord },
  data () {
    const actionRights = this.$getPageActions([
      { name: 'detail', url: '/meterRead/meterReadingManage/detail' },
      { name: 'file', url: '/meterRead/meterReadingManage/file' },
      { name: 'edit', url: '/meterRead/meterReadingManage/edit' },
      { name: 'editRecord', url: '/meterRead/meterReadingManage/editRecord' }
    ])
    const formFieldsCommon = [{
      name: 'usewaterCode',
      label: '用水户',
      form: { tag: 'text', dot: true },
      render: (value, field, data) => {
        const { customerName, usewaterCode } = data
        return `${customerName}(${usewaterCode})`
      }
    }, {
      name: 'meterOutNo',
      label: '水表外码',
      form: { tag: 'text', dot: true }
    }, {
      name: 'maxnumber',
      label: '量程',
      form: { tag: 'text', dot: true }
    }, {
      name: 'usewaterAddress',
      label: '用水地址',
      form: { tag: 'text', dot: true, colSpan: 3 }
    }, {
      name: 'lastReadingNum',
      label: '上次抄表行码',
      form: { tag: 'text', dot: true },
      render: { type: 'padString', suffix: 'm³' }
    }, {
      name: 'thisReadingNum',
      label: '本次抄表行码',
      form: { tag: 'text', dot: true },
      render: { type: 'padString', suffix: 'm³' }
    }, {
      name: 'quantity',
      label: '水量',
      form: { tag: 'text', dot: true },
      render: { type: 'padString', suffix: 'm³' }
    }, {
      name: 'estimate',
      label: '估抄',
      form: { tag: 'text', dot: true },
      render: { type: 'yesOrNo' }
    }, {
      name: 'meterCondition',
      label: '表况',
      relation: 'meterStatus',
      form: { tag: 'text', dot: true, colSpan: 2 },
      render: { type: 'select' }
    }]
    // 补充附件表单配置
    const fileFields = formFieldsCommon.concat([{
      name: 'readingAttchIds',
      label: '附件',
      form: {
        tag: 'file',
        typeLimit: ['jpg', 'jpeg', 'png'],
        resource: '/wfbase/upload',
        resType: 'READING',
        colSpan: 3
      }
    }])
    // 编辑表单配置
    const editFields = formFieldsCommon.concat([{
      name: 'readingAttchs',
      label: '附件',
      form: { tag: 'text', dot: true, colSpan: 3 },
      render: { type: 'file', showType: 'image' }
    }]).map(field => {
      switch (field.name) {
        case 'thisReadingNum':
          return {
            name: 'thisReadingNum',
            label: '本次抄表行码',
            form: {
              tag: 'number',
              disabled: false,
              min: 0,
              chains: ({ chains, values, value }) => {
                const quantity = quantityCalc(values)
                chains.setValue('quantity', quantity)
              }
            }
          }
        case 'quantity':
          return {
            name: 'quantity',
            label: '水量',
            form: {
              tag: 'number',
              disabled: true,
              min: 0,
              chains: ({ chains, values, value }) => {
                const thisReadingNum = thisReadingNumCalc(values)
                chains.setValue('thisReadingNum', thisReadingNum)
              }
            }
          }
        default:
          return field
      }
    })
    return {
      showTable: false,
      extraFilterBy: {
        readingStatus: ['UNREAD', 'READED']
      },
      readingStatusClassFunc,
      // 表格筛选表单配置
      filterFields: [{
        name: 'usewaterCode',
        label: '用水户号',
        form: {
          tag: 'input',
          like: true
        }
      }, {
        name: 'customerCode',
        label: '用户编号',
        form: {
          tag: 'input',
          like: true
        }
      }, {
        name: 'customerName',
        label: '用户姓名',
        form: {
          tag: 'input',
          like: true
        }
      }, {
        name: 'orgId',
        label: '水厂',
        form: {
          tag: 'select',
          multiple: true,
          collapseTags: true,
          clearable: true,
          filterable: true,
          relation: 'waterWorks'
        }
      }, {
        name: 'readingStaffName',
        label: '抄表员',
        form: {
          tag: 'input',
          like: true
        }
      }, {
        name: 'readingTime',
        label: '抄表时间',
        form: {
          tag: 'date',
          dateType: 'daterange'
        }
      }, {
        name: 'readingStatus',
        label: '抄表状态',
        form: {
          tag: 'select',
          clearable: true,
          relation: 'readingStatus'
        }
      }],
      // 表格列配置
      columns: [
        { name: 'readingTime', label: '抄表时间', minWidth: 140, render: { type: 'date', timeType: 'YYYY-MM-DD HH:mm' } },
        { name: 'readingStatus', label: '抄表状态', relation: 'readingStatus', render: { type: 'select' } },
        { name: 'usewaterCode', label: '用水户号', minWidth: 120 },
        {
          name: 'customerName',
          label: '用户',
          width: 160,
          render: (value, field, data) => {
            const { customerName, customerCode } = data
            return `${customerName}(${customerCode})`
          }
        },
        { name: 'lastReadingNum', label: '上次抄表行码', minWidth: 120 },
        { name: 'thisReadingNum', label: '本次抄表行码', minWidth: 120 },
        { name: 'quantity', label: '水量' },
        { name: 'estimate', label: '估抄', render: { type: 'yesOrNo' } },
        { name: 'meterCondition', label: '表况', relation: 'meterStatus', render: { type: 'select' } },
        { name: 'usewaterAddress', label: '用水地址', minWidth: 200 },
        { name: 'readingStaffName', label: '抄表员', minWidth: 100 },
        { name: 'meterNo', label: '水表编号' },
        { name: 'meterType', label: '水表类型', relation: 'meterType', render: { type: 'select' } },
        { name: 'orgName', label: '水厂', minWidth: 140 },
        { name: 'createTime', label: '创建日期', minWidth: 120, render: { type: 'date', timeType: 'YYYY-MM-DD' } }
      ],
      operateButtonList: [{
        name: 'detail',
        label: '详情',
        type: 'text',
        textType: 'primary',
        showabled: actionRights.detail,
        func: ({ data }) => {
          this.$router.push({
            name: 'meterReadingDetail',
            params: {
              recordId: data.id
            }
          })
        }
      }, {
        label: '',
        name: 'more',
        type: 'text',
        textType: 'primary',
        icon: 'el-icon-more',
        // 满足最低显示条件就显示
        showabled: (data) => {
          const buttonRight = actionRights.file || actionRights.edit || actionRights.editRecord
          return buttonRight && !['UNREAD'].includes(data.readingStatus)
        },
        dropdownButtons: [{
          name: 'file',
          label: '补充附件',
          type: 'text',
          textType: 'primary',
          showabled: (data) => actionRights.file && !['UNREAD'].includes(data.readingStatus),
          func: ({ data }) => {
            return new Promise((resolve, reject) => {
              this.getRecordById({ data })
                .then(record => {
                  resolve(record)
                  this.handleOpenFileDialog(record)
                })
                .catch(reject)
            })
          }
        }, {
          label: '修改',
          name: 'edit',
          type: 'text',
          textType: 'primary',
          showabled: (data) => actionRights.edit && !['UNREAD', 'READED'].includes(data.readingStatus),
          func: ({ data }) => {
            return new Promise((resolve, reject) => {
              this.getRecordById({ data })
                .then(record => {
                  resolve(record)
                  this.handleOpenEditDialog(record)
                })
                .catch(reject)
            })
          }
        }, {
          label: '修改记录',
          name: 'editRecord',
          type: 'text',
          textType: 'primary',
          showabled: (data) => actionRights.editRecord && !['UNREAD', 'READED'].includes(data.readingStatus),
          func: ({ data }) => {
            return new Promise((resolve, reject) => {
              this.$get({ url: `/meterReadingLog/list/${data.id}` })
                .then(res => {
                  if (!res) {
                    reject(res)
                    return
                  }
                  this.recordData = res.data || []
                  this.showEditRecord = true
                  resolve([])
                })
                .catch(reject)
            })
          }
        }]
      }],
      // 弹窗配置
      dialogTitle: '',
      visible: false,
      // 表单配置，补充附件用fileFields 编辑用editFields
      formFields: formFieldsCommon,
      editFields,
      fileFields,
      // 表单值
      formData: {},
      dialogButtonList: [{
        name: 'confirm',
        label: '保存',
        type: 'primary',
        func: () => {}
      }, {
        name: 'close',
        label: '关闭',
        func: this.handleDialogClose
      }],
      // 修改记录弹窗
      showEditRecord: false,
      recordData: []
    }
  },
  methods: {
    ...mapActions('relation', ['getRelations']),
    getData () {
      this.$refs.dataTable.reRenderTableData()
    },
    renderRelation () {
      this.getRelations(['readingStatus', 'meterStatus', 'meterType', 'waterWorks'])
        .then(res => {
          this.$renderRelationColumns(res, [this.columns, this.filterFields, this.formFields])
          this.$nextTick(() => { this.showTable = true })
        })
    },
    // 表格双击
    handleRowDblClick (row) {
      const detailButton = this.operateButtonList[0]
      detailButton.showabled && detailButton.func({ data: row })
    },
    // 获取当前抄表记录
    getRecordById ({ data }) {
      return new Promise((resolve, reject) => {
        this.$get({
          url: `/meterReading/get/${data.id}`
        })
          .then(res => {
            if (!res) {
              reject(res)
            } else {
              resolve(res.data)
            }
          })
          .catch(reject)
      })
    },
    // 打开附件修改
    handleOpenFileDialog (data) {
      this.formFields = this.fileFields
      this.dialogTitle = '补充附件'
      this.dialogButtonList[0].func = this.handleFileSave
      this.formData = { ...data }
      this.formData.readingAttchIds = cloneDeep(data.readingAttchs)
      this.visible = true
    },
    // 打开编辑
    handleOpenEditDialog (data) {
      this.editFields.forEach((item, index) => {
        // 估抄时，本次抄表指读不可修改
        if (item.name === 'thisReadingNum') this.editFields[index].form.disabled = data.estimate
        else if (item.name === 'quantity') this.editFields[index].form.disabled = !data.estimate
      })
      this.formFields = this.editFields
      this.dialogTitle = '编辑'
      this.dialogButtonList[0].func = this.handleEditSave
      this.formData = { ...data }
      // this.formData.readingAttchIds = cloneDeep(data.readingAttchs)
      this.visible = true
    },
    // 弹窗关闭
    handleDialogClose () {
      this.visible = false
      this.dialogTitle = ''
      this.formData = {}
      this.formFields = []
      this.dialogButtonList[0].func = () => {}
    },
    // 附件修改保存
    handleFileSave () {
      return new Promise((resolve, reject) => {
        this.$patch({
          url: `/meterReading/updateReadingData/${this.formData.id}`,
          data: this.formData
        })
          .then(res => {
            if (!res) {
              reject(res)
              return
            }
            resolve('success')
            this.$message.success('保存成功')
            this.visible = false
            this.formData = {}
            this.getData()
          })
          .catch(reject)
      })
    },
    // 编辑保存
    handleEditSave () {
      return new Promise((resolve, reject) => {
        this.$patch({
          url: `/meterReading/update/${this.formData.id}`,
          data: this.formData
        })
          .then(res => {
            if (!res) {
              reject(res)
              return
            }
            resolve('success')
            this.$message.success('保存成功')
            this.visible = false
            this.formData = {}
            this.getData()
          })
          .catch(reject)
      })
    }
  },
  mounted () {
    this.renderRelation()
  }
}
</script>

<style lang="sass" scoped>
.wf-table
  display: flex
  flex: 1
  flex-direction: column
  background-color: #eee
  width: 100%
  height: 100%
.form-box
  width: 100%
  height: 100%
  overflow-y: auto
  padding: 0 8px
</style>
