<template lang="pug">
el-dialog.dialog-box(
  title="修改记录"
  width="50%"
  append-to-body
  :visible.sync="visible"
  :before-close="handleDialogClose")
  DataTable.record-table(
    v-if="visible"
    ref="dataTable"
    :data="recordData"
    :serverSide="false"
    :showFilter="false"
    :hasPage="false"
    :settingButtonList="[]"
    :filterFields="[]"
    :columns="columns"
    :defaultSortBy="[{prop: 'operateTime', order: 'desc'}]")
</template>

<script>
/**
 * 抄表记录-修改记录
 */

export default {
  name: 'EditRecord',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    recordData: {
      type: Array,
      default: () => ([])
    }
  },
  computed: {
    visible: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  data () {
    return {
      columns: [
        { name: 'oriValue', label: '原值', minWidth: 120 },
        { name: 'changeValue', label: '变更值', minWidth: 120 },
        { name: 'operateTime', label: '操作时间', minWidth: 150, render: { type: 'date', timeType: 'YYYY-MM-DD HH:mm' } },
        { name: 'operator', label: '操作人', minWidth: 120 }
      ]
    }
  },
  methods: {
    handleDialogClose () {
      this.$emit('input', false)
    },
    transformResponse (response) {
      const data = response.data || []
      response.data = {
        total: data.length,
        data: data
      }
      return response
    }
  }
}
</script>

<style lang="sass" scoped>
.record-table
  height: 500px
</style>
